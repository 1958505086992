const actions = {
    GET_ONBOARDING: "GET_ONBOARDING",
    SET_ONBOARDING: "SET_ONBOARDING",

    CREATE_ONBOARDING: "CREATE_ONBOARDING",
    LOADING: "LOADING",
    DELETE_ONBOARDING: "DELETE_ONBOARDING",
    UPDATE_ONBOARDING: "UPDATE_ONBOARDING",
    FILTER_ONBOARDING: "FILTER_ONBOARDING"
}
export default actions