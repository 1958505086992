const agencyActions = {
  SET_AGENCY_STATE: "SET_AGENCY_STATE",
  GET_AGENCY: "GET_AGENCY",
  CREATE_AGENCY: "CREATE_AGENCY",
  UPDATE_AGENCY: "UPDATE_AGENCY",
  DELETE_AGENCY: "DELETE_AGENCY",
  SET_AGENCY_LOADING: "SET_AGENCY_LOADING",
  GET_AGENCY_DETAIL_BY_SLUG: "GET_AGENCY_DETAIL_BY_SLUG",
  GET_AGENCY_COUNT: "GET_AGENCY_COUNT",
  SET_AGENCY_COUNT: "SET_AGENCY_COUNT",
  GET_AGENCY_DASHBOARD: "GET_AGENCY_DASHBOARD",
  SET_AGENCY_DASHBOARD: "SET_AGENCY_DASHBOARD",
  GET_AGENCY_DASHBOARD_TABLE_DATA: "GET_AGENCY_DASHBOARD_TABLE_DATA",
  SET_AGENCY_DASHBOARD_TABLE_DATA: "SET_AGENCY_DASHBOARD_TABLE_DATA",
};
export default agencyActions;
