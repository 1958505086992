const actions = {
  PAYMENT_LOADING: "PAYMENT_LOADING",
  PAYMENT_SET_STATE: "PAYMENT_SET_STATE",
  CREATE_ORDER_INSTANCE: "CREATE_ORDER_INSTANCE",
  CAPTURE_PAYMENT: "CAPTURE_PAYMENT",
  ClEAR_PAYMENT_STATE: "ClEAR_PAYMENT_STATE",
  PAYMENT_SUCCESSFUL: "PAYMENT_SUCCESSFUL",
  PAYMENT_SUCCESSFUL_MAIL : "PAYMENT_SUCCESSFUL_MAIL"
};
export default actions;
