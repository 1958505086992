const actions = {
    GET_JOBCAT: "GET_JOBCAT",
    GET_ALL_JOBCAT: "GET_ALL_JOBCAT",
    SET_JOBCAT: "SET_JOBCAT",
    CREATE_JOBCAT: "CREATE_JOBCAT",
    LOADING: "LOADING",
    DELETE_JOBCAT: "DELETE_JOBCAT",
    UPDATE_JOBCAT: "UPDATE_JOBCAT",
    FILTER_JOBCAT: "FILTER_JOBCAT"
}
export default actions