const actions = {
  GET_LEAD: "GET_LEAD",
  SET_LEAD: "SET_LEAD",
  CREATE_LEAD: "CREATE_LEAD",
  LOADING: "LOADING",
  DELETE_LEAD: "DELETE_LEAD",
  UPDATE_LEAD: "UPDATE_LEAD",
  FILTER_LEAD: "FILTER_LEAD",
  APPROVE_LEAD: "APPROVE_LEAD",
  DECLINED_LEAD: "DECLINED_LEAD",
};
export default actions;
