const actions = {
    GET_INTERVIEW: "GET_INTERVIEW",
    SET_INTERVIEW: "SET_INTERVIEW",
    CREATE_INTERVIEW: "CREATE_INTERVIEW",
    LOADING: "LOADING",
    DELETE_INTERVIEW: "DELETE_INTERVIEW",
    UPDATE_INTERVIEW: "UPDATE_INTERVIEW",
    FILTER_INTERVIEW: "FILTER_INTERVIEW",
    SET_DASHBOARD_INTERVIEW: "SET_DASHBOARD_INTERVIEW"
}
export default actions