const actions = {
    GET_INDUSTRIES: "GET_INDUSTRIES",
    GET_ALL_INDUSTRIES: "GET_ALL_INDUSTRIES",
    SET_INDUSTRIES: "SET_INDUSTRIES",
    CREATE_INDUSTRIES: "CREATE_INDUSTRIES",
    LOADING: "LOADING",
    DELETE_INDUSTRIES: "DELETE_INDUSTRIES",
    UPDATE_INDUSTRIES: "UPDATE_INDUSTRIES",
    FILTER_INDUSTRIES: "FILTER_INDUSTRIES"
}
export default actions