const actions = {
    GET_CLIENT: "GET_CLIENT",
    GET_CLIENT_Detail: "GET_CLIENT_Detail",
    GET_All_CLIENT: "GET_All_CLIENT",
    SET_CLIENT: "SET_CLIENT",
    SET_ERROR: "SET_ERROR",
    CLIENT_LOADING: "CLIENT_LOADING",
    CREATE_CLIENT: "CREATE_CLIENT",
    UPDATE_CLIENT_PROFILE: "UPDATE_CLIENT_PROFILE",
    DELETE_CLIENT: "DELETE_CLIENT",
    UPDATE_CLIENT: "UPDATE_CLIENT",
    FILTER_CLIENT: "FILTER_CLIENT",
    APPROVE_CLIENT: "APPROVE_CLIENT",
    DECLINED_CLIENT: " DECLINED_CLIENT",
    PUBLIC_CLIENT: "PUBLIC_CLIENT",
    INTERVIEW_REQUEST: "INTERVIEW_REQUEST",
    CLIENT_ISSUCCESS: "CLIENT_ISSUCCESS",
    INTERVIEW_REQUEST_POPUP: "INTERVIEW_REQUEST_POPUP",
    WHATSAPP_NOTIFICATION_STATUS: "WHATSAPP_NOTIFICATION_STATUS",
    MAIL_NOTIFICATION_STATUS: "MAIL_NOTIFICATION_STATUS",
}
export default actions