const actions = {
    GET_USER: "GET_USER",
    SET_USER: "SET_USER",
    GET_USER_ROLE_WISE: "GET_USER_ROLE_WISE",
    CREATE_USER: "CREATE_USER",
    LOADING: "LOADING",
    DELETE_USER: "DELETE_USER",
    UPDATE_USER: "UPDATE_USER",
    FILTER_USER: "FILTER_USER",
    GET_USER_DETAILS: "GET_USER_DETAILS",
    GET_LOGIN_USER_DETAIL: "GET_LOGIN_USER_DETAIL",
    CREATE_FREE_PLAN: "CREATE_FREE_PLAN"
}
export default actions